import React, { useState, useEffect } from 'react'
import ReactConfetti from 'react-confetti'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import ParticleRouletteSpinning from '@/components/molecules/Ranking/Event/YellGacha/ParticleRouletteSpinning'

const StyledContainer = styled.div`
  position: absolute;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
  display: flex;
`

const StyledAlertBG = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledAlert = styled.div<{ boostRatio: number }>`
  display: flex;
  position: relative;
  background: ${({ boostRatio }) => (boostRatio > 1 ? '#FFD8E7' : Colors.WHITE)};
  background-image: ${({ boostRatio }) =>
    boostRatio > 1
      ? "url('/ranking/gacha/background_boost.webp')"
      : "url('/ranking/gacha/background.webp')"};
  background-size: 100% auto;
  background-position: center;
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  width: calc(100% - 80px);
  z-index: 10003;
  padding: 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

const StyledMain = styled.div<{ boostRatio: number }>`
  margin: 30px 10px;
  height: ${({ boostRatio }) => (boostRatio > 1 ? 'calc(100vw * 0.84)' : 'calc(100vw * 0.78)')};
  max-height: 400px;
`

const StyledMainTitle = styled.img`
  width: 90%;
  margin: 0 auto;
`
const StyledMainBoostTitle = styled.img`
  margin-top: 4px;
  width: 60%;
  margin: 0 auto;
`

const StyledMainRouletteContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 48% auto 0 auto;
`

const StyledMainRouletteFrame = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1;
`

const spinTopLeft = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(5454deg);
  }
`
const spinLeft = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(5510deg);
  }
`
const spinBottomLeft = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(5214deg);
  }
`
const spinBottomRight = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(5232deg);
  }
`
const spinRight = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(5286deg);
  }
`
const spinTopRight = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(5390deg);
  }
`

const StyledMainRoulette = styled.img<{ animationPattern: string }>`
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
  animation: ${({ animationPattern }) => {
      switch (animationPattern) {
        case 'spinTopLeft':
          return spinTopLeft
        case 'spinLeft':
          return spinLeft
        case 'spinBottomLeft':
          return spinBottomLeft
        case 'spinBottomRight':
          return spinBottomRight
        case 'spinRight':
          return spinRight
        case 'spinTopRight':
          return spinTopRight
        default:
          return 'none'
      }
    }}
    7s cubic-bezier(0.25, 0, 0.01, 1) forwards;
`

const StyledMainRoulettePointer = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -58%);
  width: 20%;
  z-index: 3;
`

const StyledSkipButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

interface Props {
  yellCount: number
  onNext: () => void
  boostRatio: number
}

const RankingYellGachaDialog = ({ yellCount, onNext, boostRatio }: Props) => {
  const [visibleConfetti, setVisibleConfetti] = useState(false)

  useEffect(() => {
    const timerShow = setTimeout(() => {
      setVisibleConfetti(true)
    }, 7500)

    const timerHide = setTimeout(
      () => {
        setVisibleConfetti(false)
        onNext()
      },
      yellCount > 0 ? 11000 : 9000,
    ) // yellCount > 0 の場合は紙吹雪演出があるので少し長く（ハズレの場合はその演出なく閉じる）

    return () => {
      setVisibleConfetti(false)
      clearTimeout(timerShow)
      clearTimeout(timerHide)
    }
  }, [yellCount])

  if (yellCount === 0) return null

  return (
    <>
      <StyledContainer>
        <StyledAlertBG />
        <StyledAlert boostRatio={boostRatio}>
          <StyledMain boostRatio={boostRatio}>
            <StyledMainTitle src="/ranking/gacha/text_lets_gacha.webp" />
            {boostRatio > 1 && <StyledMainBoostTitle src="/ranking/gacha/text_boost.webp" />}
            <StyledMainRouletteContainer>
              <StyledMainRouletteFrame src="/ranking/gacha/gacha_roulette_frame.webp" />
              {boostRatio > 1 ? (
                <StyledMainRoulette
                  src="/ranking/gacha/gacha_roulette_boost.webp"
                  animationPattern={
                    {
                      600: 'spinTopLeft',
                      2000: 'spinLeft',
                      200: 'spinBottomLeft',
                      1600: 'spinBottomRight',
                      1000: 'spinRight',
                      20000: 'spinTopRight',
                    }[yellCount] || 'spinBottomLeft'
                  }
                />
              ) : (
                <StyledMainRoulette
                  src="/ranking/gacha/gacha_roulette.webp"
                  animationPattern={
                    {
                      300: 'spinTopLeft',
                      1000: 'spinLeft',
                      100: 'spinBottomLeft',
                      800: 'spinBottomRight',
                      500: 'spinRight',
                      10000: 'spinTopRight',
                    }[yellCount] || 'spinBottomLeft'
                  }
                />
              )}
              <StyledMainRoulettePointer src="/ranking/gacha/gacha_roulette_pointer.webp" />
              <ParticleRouletteSpinning />
            </StyledMainRouletteContainer>
          </StyledMain>

          <StyledSkipButtonContainer
            onClick={() => {
              onNext()
            }}
          >
            スキップ
          </StyledSkipButtonContainer>
        </StyledAlert>

        {/* 紙吹雪 */}
        {visibleConfetti && yellCount > 0 && (
          <ReactConfetti
            width={Math.min(window.innerWidth, 480)}
            height={window.innerHeight}
            recycle={true}
            style={{ zIndex: 100000, position: 'absolute' }}
          />
        )}
      </StyledContainer>
    </>
  )
}

export default RankingYellGachaDialog
