import React from 'react'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'
import { CreatorData } from '@/types/Creator.type'

const StyledBadgeContainer = styled.div`
  position: relative;
  width: 200px;
  margin: 16px auto;
`

const StyledBadgeImage = styled.img`
  width: 100%;
  display: block;
  cursor: pointer;
`

const StyledCreatorImage = styled.img`
  width: 34px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 5px;
  background-color: white;
  cursor: pointer;
`

interface Props {
  creatorData?: CreatorData
  setVisibleBadgeDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const RankingFanBadgeForCreator = ({ creatorData, setVisibleBadgeDialog }: Props) => {
  const router = useRouter()

  return (
    <>
      {creatorData?.user_badge?.badge?.badge_top_url && (
        <StyledBadgeContainer>
          <StyledBadgeImage
            src={creatorData.user_badge.badge.badge_top_url}
            alt="badge"
            onClick={() => setVisibleBadgeDialog(true)}
          />
          <StyledCreatorImage
            src={creatorData.user_badge.creator.icon}
            alt="creator"
            onClick={() => router.push(`/@${creatorData?.user_badge?.creator.account_identity}`)}
          />
        </StyledBadgeContainer>
      )}
    </>
  )
}

export default RankingFanBadgeForCreator
