import React, { useRef } from 'react'
import { CreatorPopUpData } from '@/types/Creator.type'
import {
  StyledButton,
  StyledButtonText,
  StyledCard,
  StyledCloseButton,
  StyledPopupContent,
  StyledPopupImage,
  StyledPopupWrapper,
  StyledText,
} from './index.style'

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  popUpData: CreatorPopUpData
}

const PopUpModal = ({ visible, setVisible, popUpData }: Props) => {
  // StyledCardの外側をクリックしたら閉じる
  const refCard = useRef<HTMLDivElement>(null)
  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (refCard.current && !refCard.current.contains(event.target as Node)) {
      setVisible(false)
    }
  }

  if (!visible) return null
  return (
    <StyledPopupWrapper onClick={handleClickOutside}>
      <StyledPopupContent>
        <StyledCard ref={refCard}>
          <div className="rounded-[14px] overflow-hidden">
            <StyledPopupImage
              src={popUpData?.image}
              alt={popUpData?.title || ''}
              onClick={() => (location.href = popUpData.url)}
            />
          </div>
          <StyledText>{popUpData?.title ? popUpData.title : ''}</StyledText>
          <StyledButton onClick={() => (location.href = popUpData.url)}>
            <StyledButtonText>{popUpData?.button_text}</StyledButtonText>
          </StyledButton>
        </StyledCard>
        <StyledCloseButton onClick={() => setVisible(false)}>閉じる</StyledCloseButton>
      </StyledPopupContent>
    </StyledPopupWrapper>
  )
}

export default PopUpModal
