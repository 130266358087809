import styled from '@emotion/styled'
import { Colors, contentShadow } from '@/constants/styles/color'

const StyledItemContainer = styled.div<{
  isDark?: boolean
  isLink?: boolean
}>`
  width: 100%;
  min-height: 80px;
  margin: 0 auto;
  justify-content: center;
  background: ${({ isDark }) => (isDark ? 'transparent' : Colors.WHITE)};
  color: ${({ isDark }) => (isDark ? Colors.WHITE : Colors.BLACK)};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  box-shadow: ${({ isDark, isLink }) =>
    isLink ? `${isDark ? contentShadow.dark : contentShadow.light}` : 'none'};
  border: ${({ isDark }) =>
    isDark ? `1px solid ${Colors.WHITE}` : `1px solid ${Colors.SILVER_GRAY}`};
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};

  ${({ isLink }) =>
    isLink &&
    `
    &:hover {
      opacity: 0.8;
    }
    `}
`

const StyledItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
`

const StyledItem = styled.div<{ title?: string; description?: string }>`
  width: 100%;
  height: 100%;
  display: grid;
  align-content: space-around;
  grid-template-rows: ${({ title, description }) => (title && description ? '24px auto' : 'none')};
`

const StyledTextWrapper = styled.div<{ overFlow?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: ${({ overFlow }) => (overFlow ? 'hidden' : 'visible')};
`

export { StyledItemContainer, StyledItemWrapper, StyledItem, StyledTextWrapper }
