import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Colors } from '@/constants/styles/color'
import { event } from '@/pages/api/gtag'
import { useThemeContext } from '@/contexts/ThemeContext'
import { StyledDescriptionText, StyledListContents, StyledTitleText } from '../index.style'
import {
  StyledItem,
  StyledItemContainer,
  StyledItemWrapper,
  StyledTextWrapper,
} from './index.style'

interface Props {
  id: number
  link: string
  title: string
  description?: string
}

const TextList = ({ id, link, title, description }: Props) => {
  const { ref, inView } = useInView()
  const { isDark } = useThemeContext()

  const onClick = (id: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'links',
      value: id,
    })
  }

  const textListContent = () => (
    <StyledItemContainer isDark={isDark} isLink={link ? true : false}>
      <StyledItemWrapper>
        <StyledItem title={title} description={description}>
          {title && (
            <StyledTextWrapper overFlow>
              <StyledTitleText
                color={isDark ? Colors.WHITE : Colors.BLACK}
                className="jp-title middle"
              >
                {title}
              </StyledTitleText>
            </StyledTextWrapper>
          )}
          {description && (
            <StyledTextWrapper>
              <StyledDescriptionText
                color={isDark ? Colors.WHITE : Colors.BLACK}
                className="jp-text small"
              >
                {description}
              </StyledDescriptionText>
            </StyledTextWrapper>
          )}
        </StyledItem>
      </StyledItemWrapper>
    </StyledItemContainer>
  )

  return (
    <StyledListContents ref={ref} inView={inView}>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          onClick={() => onClick(id)}
          className={link?.includes('/apps/') ? 'ranking-anchor' : ''}
        >
          {textListContent()}
        </a>
      ) : (
        <>{textListContent()}</>
      )}
    </StyledListContents>
  )
}

export default TextList
