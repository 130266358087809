import styled from '@emotion/styled'
import { Colors, Gradation } from '@/constants/styles/color'

const StyledPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledCard = styled.div`
  padding: 16px;
  background: white;
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  margin: 0 auto;

  @media (min-width: 480px) {
    max-width: 440px;
  }
`

const StyledPopupImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 480px;
`

const StyledText = styled.p`
  text-align: center;
  margin: 16px 0;
`

const StyledButton = styled.button`
  background: ${Colors.BLACK};
  width: 80%;
  margin: 8px auto 0;
  max-height: 72px;
  padding: 12px;
  border-radius: 24px;
  border: none;
  color: ${Colors.WHITE};
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonText = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: normal;
  font-size: 11px;
`

const StyledCloseButton = styled.button`
  width: 88px;
  height: 40px;
  margin: 24px auto;
  font-size: 12px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  color: ${Colors.BLACK};
  background-clip: content-box, border-box;
  background: ${Colors.WHITE};
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${Colors.WHITE};
  }
`
export {
  StyledCard,
  StyledPopupImage,
  StyledText,
  StyledButton,
  StyledButtonText,
  StyledCloseButton,
  StyledPopupWrapper,
  StyledPopupContent,
}
