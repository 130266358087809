import React from 'react'
import Link from 'next/link'
import styled from '@emotion/styled'

const StyledGeneralEventContainer = styled.div`
  margin: 16px 0;
  padding: 8px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: 100% auto;
  background-image: url('/general_event/background_fanme_pink.svg');
  background-repeat: repeat-y;
`

interface Props {
  accountIdentity: string
  eventId: number
}

const GeneralEventAreaResult = ({ accountIdentity, eventId }: Props) => {
  return (
    <StyledGeneralEventContainer>
      <Link href={`/@${accountIdentity}/event/${eventId}/fans`}>
        <img src="/general_event/event-result.webp" alt="イベント結果" className="pt-3 pb-1 px-1" />
      </Link>
    </StyledGeneralEventContainer>
  )
}

export default GeneralEventAreaResult
