import React from 'react'
import { Text } from '@nextui-org/react'

import { Colors } from '@/constants/styles/color'
import PlusIcon from '@/assets/img/icons/Plus.svg'
import { useThemeContext } from '@/contexts/ThemeContext'

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ReadMoreButton = ({ onClick }: Props) => {
  const { isDark } = useThemeContext()

  return (
    <button
      style={{
        background: isDark ? Colors.BLACK : Colors.VERY_LIGHT_GRAY,
        borderRadius: '20px',
        border: isDark ? `0.5px solid ${Colors.GRAY}` : 'none',
        margin: '0 auto',
        zIndex: 0,
        display: 'flex',
        alignItems: 'center',
        width: '112px',
        minWidth: '112px',
        height: '24px',
        padding: '8px 6px 8px 8px',
      }}
      onClick={onClick}
    >
      <Text
        css={{
          w: '86px',
          ta: 'center',
          letterSpacing: '0.1em',
          fontStyle: 'normal',
          fontWeight: '500',
          fs: '10px',
          lh: '10px',
          color: isDark ? Colors.GRAY : Colors.BLACK,
        }}
      >
        READ MORE
      </Text>
      <PlusIcon width={12} height={12} style={{ fill: `${isDark ? Colors.GRAY : Colors.BLACK}` }} />
    </button>
  )
}

export default ReadMoreButton
