import React, { useEffect, useState } from 'react'
import { useModal } from '@nextui-org/react'

import { CreatorPopUpData } from '@/types/Creator.type'
import PopUpModal from '@/components/atoms/Dialog/PopUpModal'

interface Props {
  icon: React.ReactElement
  popUpData: CreatorPopUpData
  initialVisible: boolean
  enable: boolean
  onHide?: () => void
  isCurrentPopup: boolean
  sendPopupVisibleToParent: (visible: boolean) => void
}

const LeftSideMenuButton = ({
  popUpData,
  enable = true,
  initialVisible = false,
  onHide,
  isCurrentPopup,
  sendPopupVisibleToParent,
}: Props) => {
  const { setVisible, visible } = useModal(initialVisible)
  const handleVisibleChanged = (visible: boolean) => {
    setVisible(visible)
    sendPopupVisibleToParent(visible)
  }
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (!enable) {
      return
    }

    if (!visible && loaded) {
      if (typeof onHide === 'function') {
        onHide()
      }
    }
  }, [visible])

  useEffect(() => {
    handleVisibleChanged(initialVisible)
    if (!loaded) {
      setLoaded(true)
    }
  }, [])

  if (!enable) {
    return null
  }

  return (
    <PopUpModal
      visible={isCurrentPopup && visible}
      setVisible={handleVisibleChanged}
      popUpData={popUpData}
    />
  )
}

export default LeftSideMenuButton
