import styled from '@emotion/styled'
import { Colors, contentShadow } from '@/constants/styles/color'

const StyledImageList = styled.div<{
  isLink?: boolean
  isDark?: boolean
}>`
  width: 100%;
  height: 80px;
  margin: 0 auto;
  background: ${props => (props.isDark ? 'transparent' : Colors.WHITE)};
  color: ${props => (props.isDark ? Colors.WHITE : Colors.BLACK)};
  padding: 8px 12px 8px 8px;
  box-shadow: ${props =>
    props.isLink ? `${props.isDark ? contentShadow.dark : contentShadow.light}` : 'none'};
  border: ${props =>
    props.isDark ? `1px solid ${Colors.WHITE}` : `1px solid ${Colors.SILVER_GRAY}`};
  display: flex;
  align-items: center;
  border-radius: 16px;
  cursor: ${props => (props.isLink ? 'pointer' : 'default')};
  ${props => (props.isLink ? '&:hover { opacity: 0.8; }' : '')}
`

const StyledImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 16px;
  object-fit: cover;
`

const StyledImageListContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 64px auto;
  align-items: center;
  overflow: hidden;
`

const StyledImageListNonImg = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background: ${Colors.BK_GRAY};
`

const StyledImageListItem = styled.div<{ isExistItem: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 0 0 12px;
  display: grid;
  align-items: center;
  grid-template-rows: ${props => (props.isExistItem ? '24px auto' : '')};
`

const StyledImageListTitle = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  p {
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px 0;
    margin: 0;
  }
`

const StyledImageListDescription = styled.div`
  display: flex;
  align-items: center;
  p {
    line-height: 17px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-width: 232px;
    overflow-wrap: break-word;
    white-space: pre-line;
    margin: 0;
  }
`

export {
  StyledImageList,
  StyledImageListContainer,
  StyledImageListDescription,
  StyledImageListItem,
  StyledImageListNonImg,
  StyledImageListTitle,
  StyledImage,
}
