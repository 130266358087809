import React, { useState, useEffect, useRef, RefObject } from 'react'
import getConfig from 'next/config'
import Router, { useRouter } from 'next/router'
import Link from 'next/link'
import { TwitterShareButton } from 'react-share'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import RankCrown from '@/assets/img/ranking/rank_crown.svg'
import { useRankingEvent, RankingEventStatus } from '@/contexts/RankingEventContext'
import { CreatorData } from '@/types/Creator.type'
import PopUpModal from '@/components/atoms/Dialog/PopUpModal'
import { getSellerApps } from '@/pages/api/payment'

interface RankingAreaForCreatorProps {
  loginUserData?: CreatorData | null
  creatorData?: CreatorData
  setVisibleRankingSharePopup: React.Dispatch<React.SetStateAction<boolean>>
  setGachaYellCount: React.Dispatch<React.SetStateAction<number>>
  twitterShareButtonRef: RefObject<HTMLButtonElement>
  fanLetterButtonRef: RefObject<HTMLImageElement>
  isCurrentPopup: boolean
  sendPopupVisibleToParent: (visible: boolean) => void
}

const StyledRankingEventContainer = styled.div<{ disable?: boolean }>`
  margin: 16px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: 100% auto;
  background-image: url(${props =>
    props.disable ? '/ranking/background_fanme_gray.svg' : '/ranking/background_fanme_yellow.svg'});
  background-repeat: repeat-y;
`

const StyledCreatorRankingInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  .strong {
    font-weight: bold;
  }
`

const StyledEventTitleImageWraper = styled.div`
  width: 30%;
  margin: 0 24px 0 0;
`
const StyledEventTitleImage = styled.img`
  width: 100%;
`

const StyledCreatorRankingButtonsContainer = styled.div`
  text-align: center;
  min-width: 90%;
  margin-top: 16px;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #ff8a00;
  background: #ffe7c0;
  border-radius: 16px;

  div.message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  span {
    margin-left: 8px;
  }
  span.boosting {
    color: #fe551e;
  }
  img.yell-comment {
    height: 28px;
    margin: 0 4px;
  }
`

const StyledCreatorRankingButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0 0 0;
`
const StyledRankingTextSpan = styled.span`
  font-size: 15px;
  font-family: 'Roboto';
  font-weight: bold;
  margin: 0 0 0 8px;
`

const StyledButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledCircleLoading = styled.div`
  box-sizing: border-box;
  margin: 0 70px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  animation: ${spin} 0.5s infinite linear;
`
const StyledYellCount = styled.span`
  position: absolute;
  top: 21%;
  right: 40%;
  font-size: 12px;
  z-index: 1;
`

const bounce = keyframes`
  0%, 100% {
    transform: scaleX(1) scaleY(1);
  }
  25% {
    transform: scaleX(1.1) scaleY(0.9);
  }
  50% {
    transform: scaleX(0.9) scaleY(1.1);
  }
  75% {
    transform: scaleX(1.05) scaleY(0.95);
  }
`

const createShakeKeyframes = (angle: number) => keyframes`
  0%, 84%, 100% {
    transform: rotate(0deg);
  }
  88%, 92%, 96% {
    transform: rotate(${angle}deg);
  }
  86%, 90%, 94%, 98% {
    transform: rotate(-${angle}deg);
  }
`

const StyledPostYellButtonImage = styled.img<{ isClicked: boolean }>`
  width: 60%;
  animation: ${({ isClicked }) =>
    isClicked
      ? css`
          ${bounce} 0.25s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
        `
      : css`
          ${createShakeKeyframes(1)} 4s linear infinite;
        `};
`
const StyledPostYellButtonDisableImage = styled.img`
  width: 60%;
`

const StyledGachaYellButtonImage = styled.img`
  width: 28%;
  animation: ${createShakeKeyframes(2)} 4s linear infinite;
  animation-delay: 0.5s;
`
const StyledGachaYellButtonDisableImage = styled.img`
  width: 28%;
`

const StyledYellButtonImage = styled.img`
  height: 80px;
`

const StyledRankingButtonImage = styled.img`
  height: 64px;
`

const floatEmoji = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
    z-index: 100;
  }
`

const StyledBoostTitleImage = styled.img`
  width: 60%;
  margin: 0 auto 4px auto;
`

const StyledFloatImage = styled.img<{ fileName: string }>`
  position: absolute;
  z-index: 100;
  width: ${({ fileName }) => (fileName === '99.webp' ? '100px' : '60px')};
  animation: ${floatEmoji} 1s ease-in-out forwards;
  pointer-events: none;
`

const sparklingIcon = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
    z-index: 100;
  }
`

const StyledSparklingIcon = styled.div`
  position: absolute;
  opacity: 0;
  z-index: 100;
  font-size: 34px;
  animation: ${sparklingIcon} 0.5s ease-in-out forwards;
  pointer-events: none;
`

const StyledEventImage = styled.img`
  width: 60%;
  margin: 16px auto;
  display: block;
`

const StyledEventResultImage = styled.img`
  width: 90%;
  margin: 16px auto;
  display: block;
`

const StyledPopupModalContainer = styled.div`
  z-index: 10002;
`

// クリエイターページのランキングエリアを表示するコンポーネント
const RankingAreaForCreator = React.forwardRef(
  (
    {
      loginUserData,
      creatorData,
      setVisibleRankingSharePopup,
      setGachaYellCount,
      fanLetterButtonRef,
      twitterShareButtonRef,
      isCurrentPopup,
      sendPopupVisibleToParent,
    }: RankingAreaForCreatorProps,
    ref,
  ) => {
    const { publicRuntimeConfig } = getConfig()
    const router = useRouter()

    // ランキング関連
    const rankingEvent = useRankingEvent()
    const buttonPostYellRef = useRef<HTMLImageElement>(null)
    const [isPostYellClicked, setIsPostYellClicked] = useState(false)
    type IconPosition = {
      symbol: string
      x: number
      y: number
    }
    const [yellEmojis, setYellEmojis] = useState<IconPosition[]>([])
    const [sparklingIcons, setSparklingIcons] = useState<IconPosition[]>([])
    const [yellClickCount, setYellClickCount] = useState<number | null>(null)
    const [postYellTimerId, setPostYellTimerId] = useState<NodeJS.Timeout | null>(null)
    const [visibleEventPopUp, setVisibleEventPopUp] = useState<boolean>(false)
    const handlePopupVisibleChanged = (visible: boolean) => {
      setVisibleEventPopUp(visible)
      sendPopupVisibleToParent(visible)
    }

    const [apps, setApps] = useState<MiniAppInfo[] | null>(null)
    const [fanLetterUrl, setFanLetterUrl] = useState<string | null>(null)

    // 参加中のランキングを取得
    useEffect(() => {
      if (!rankingEvent) return
      rankingEvent.getJoiningEvent(false)
    }, [rankingEvent])

    // ログイン済みの場合、エール送信の状態（送信可能かどうかなど）を取得
    useEffect(() => {
      // ログインしてない場合はサーバから取得せず、ローカルで設定（エールを送るやシェアのクリックでログインを促す）
      if (!loginUserData) {
        rankingEvent.setActionable({
          can_yell_for_share: true,
          can_yell_for_click: true,
          can_yell_for_gacha: true,
          today_yell_count: 0,
          active_boost_ratio: 1,
        })
        return
      }
      // ログインしている場合はサーバからエール送信の状態を取得
      const fetchData = async () => {
        if (!rankingEvent.joiningEvent) return
        const eventIdentity = rankingEvent.joiningEvent?.ranking_event.event_identity || ''
        const creatorId = rankingEvent.joiningEvent?.creator.account_identity || ''
        if (!eventIdentity || !creatorId) return
        const actionable = await rankingEvent.getActionable(eventIdentity, `@${creatorId}`)
        setYellClickCount(actionable?.today_yell_count || 0)
      }
      fetchData()
    }, [rankingEvent.joiningEvent, loginUserData])

    // エールをサーバに送付する
    // 連打されたときにサーバ負荷が高いので、0.5秒後に送付する
    // ただし0.5秒以内に処理がきた場合は前の処理はキャンセルし、次の処理にてまとめて送付する
    const postYellTimerProp = (yellClickCount: number) => {
      if (postYellTimerId) {
        clearTimeout(postYellTimerId)
      }
      const timerId = setTimeout(() => {
        postYellProp(yellClickCount || 0)
      }, 500)
      setPostYellTimerId(timerId)
    }
    // エールのサーバ送信処理
    const postYellProp = (postYell: number) => {
      if (postYell <= 0) return
      if (!rankingEvent.joiningEvent) return
      const event_identity = rankingEvent.joiningEvent.ranking_event.event_identity
      const creator_id = `@${rankingEvent.joiningEvent.creator.account_identity}`
      rankingEvent.postYell(event_identity, creator_id, rankingEvent.YellLogType.Click, postYell)
    }

    // エール送信アクション処理
    const handlePostYell = (event: React.MouseEvent) => {
      if (!rankingEvent.joiningEvent) return
      // はじめに音尾出す
      const audio = new Audio(`/ranking/post_yell.mp3`)
      audio.play()
      // ボタンのぷにぷにアニメーション制御
      setIsPostYellClicked(true)
      setTimeout(() => setIsPostYellClicked(false), 250)
      // エール用の絵文字ふよふよ処理
      const yellEmoji = fluffyYellEmoji()
      // エールのカウントアップ（spacialのときは一気に100エールになる）
      const postYell = Math.min(
        yellEmoji == '99.webp' ? countableMaxYell() : (yellClickCount ?? 0) + 10,
        countableMaxYell(),
      )
      setYellClickCount(postYell)
      // エールの実際の送信を行う
      postYellTimerProp(postYell)
      // エールの送信完了処理
      if (postYell >= countableMaxYell()) {
        finishalizePostYell(postYell)
      }
    }
    const getRandomInt = (min: number, max: number) => {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
    // ランダムでエール用の絵文字を返す
    const getYellEmoji = (): string => {
      const emojis = ['1.webp', '2.webp', '3.webp']
      const specialEmoji = '99.webp'
      const randomValue = Math.random()
      // 1%の確率で special を返す
      if (randomValue <= 0.01) {
        return specialEmoji
      } else {
        const randomIndex = Math.floor(Math.random() * emojis.length)
        return emojis[randomIndex]
      }
    }
    // エール用の絵文字ふよふよ処理
    const fluffyYellEmoji = (): string => {
      const yellEmoji = getYellEmoji()
      if (buttonPostYellRef.current) {
        const newYellEmoji = {
          symbol: yellEmoji,
          x: buttonPostYellRef.current.width / 2 - 30 + getRandomInt(-80, 80),
          y: -20,
        }
        setYellEmojis(prevYellEmojis => [...prevYellEmojis, newYellEmoji])
      }
      return yellEmoji
    }
    // エール送信完了処理
    const finishalizePostYell = (postYell: number) => {
      // エール送信ボタンを押せなくする
      rankingEvent.setActionable({
        can_yell_for_share: rankingEvent.actionable?.can_yell_for_share || false,
        can_yell_for_click: false,
        can_yell_for_gacha: rankingEvent.actionable?.can_yell_for_gacha || false,
        today_yell_count: postYell,
        active_boost_ratio: rankingEvent.actionable?.active_boost_ratio || 1,
      })
      // キラキラを表示
      sparklingsAfterYell()
      // シェアがまだの場合、シェアを促す
      if (rankingEvent.actionable?.can_yell_for_share) {
        // すぐに表示するとタップの絵文字アニメーションがかき消されてしまうため、1秒ほど時間を置いてから表示
        setTimeout(() => {
          setVisibleRankingSharePopup(true)
        }, 1000)
      }
    }
    // エール送信完了時のキラキラ表示
    const sparklingsAfterYell = () => {
      if (!buttonPostYellRef.current) return
      const rect = buttonPostYellRef.current.getBoundingClientRect()
      for (let i = 0; i < 25; i++) {
        setTimeout(() => {
          sparklingAfterYell(rect)
        }, i * 12)
      }
    }
    const sparklingAfterYell = (rect: DOMRect) => {
      const newIcon = {
        symbol: '✨',
        x: rect.width / 2 - rect.left + getRandomInt(-200, 200),
        y: getRandomInt(-100, 100),
      }
      setSparklingIcons(prevIcons => [...prevIcons, newIcon])
    }

    // エールガチャアクション処理
    const handleGachaYell = async (event: React.MouseEvent) => {
      // ガチャ付与数を確定する（バックエンドで確定して付与しておく）
      if (!rankingEvent.joiningEvent) return
      const event_identity = rankingEvent.joiningEvent.ranking_event.event_identity
      const creator_id = `@${rankingEvent.joiningEvent.creator.account_identity}`
      const yellCount = await rankingEvent.postYell(
        event_identity,
        creator_id,
        rankingEvent.YellLogType.Gacha,
        100,
      ) // 100は仮の数値（backendからガチャ後の確定値が返ってくる）
      rankingEvent.getActionable(event_identity, creator_id)
      // GachaYellCount()を設定すると、エールガチャが発動する
      setGachaYellCount(yellCount)
    }

    // イベントポップアップの制御（1日に1回表示する）
    useEffect(() => {
      if (!rankingEvent.joiningEvent) return
      const currentTime = new Date()
      const eventStartTime = new Date(rankingEvent.joiningEvent.ranking_event.start_at)
      const eventEndTime = new Date(rankingEvent.joiningEvent.ranking_event.end_at)
      if (eventStartTime > currentTime || eventEndTime < currentTime) return
      const lastVisibleTime = localStorage.getItem('visibleRankingEventPopupDialog')
      const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000
      if (!lastVisibleTime || Number(lastVisibleTime) < oneDayAgo) {
        handlePopupVisibleChanged(true)
        localStorage.setItem('visibleRankingEventPopupDialog', String(Date.now()))
      }
    }, [rankingEvent.joiningEvent])

    // 音声を事前ロード
    useEffect(() => {
      new Audio('/ranking/post_yell.mp3')
    }, [])

    // デジタルファンレターのリンクを取得
    useEffect(() => {
      if (!rankingEvent.joiningEvent) return
      if (!creatorData) return
      if (apps) return
      getSellerApps(creatorData.uid).then(res => {
        setApps(res)
        const fanLetterApp = res.find((app: MiniAppInfo) => app.app_url_key === 'dfl')
        if (fanLetterApp) {
          setFanLetterUrl(
            `/${router.query.creator_id}/apps/${fanLetterApp.app_url_key}${fanLetterApp.seller_app_url_key}`,
          )
        }
      })
    }, [rankingEvent.joiningEvent, creatorData])

    // ランキングの表示（参加している場合にのみ表示
    if (!rankingEvent.joiningEvent) return <></>

    // 集計中のレンダリング
    const renderEventCalculating = () => {
      return (
        <StyledRankingEventContainer disable={true}>
          <StyledCreatorRankingInfo>
            <img src="/ranking/aggregating.svg" />
          </StyledCreatorRankingInfo>
          <StyledEventImage
            src={`${publicRuntimeConfig.PUBLIC_BLOB_URL}/ranking_event/${rankingEvent.joiningEvent?.ranking_event.event_identity}/event-title.webp`}
            alt={joiningEvent.ranking_event.name}
          />
        </StyledRankingEventContainer>
      )
    }

    const joiningEvent = rankingEvent.joiningEvent
    const status = rankingEvent.getEventStatus(joiningEvent.ranking_event)
    switch (status) {
      case RankingEventStatus.Preparing: // 準備中
      case RankingEventStatus.AcceptingEntries: // 参加受付中
      case RankingEventStatus.EntriesClosed: // 参加受付終了
      case RankingEventStatus.EventFinished: // イベント終了
        return <></> // joiningEventとして返ってくることはないため、実際は表示されることはない
      case RankingEventStatus.Calculating: // 集計中...
        return renderEventCalculating()
      case RankingEventStatus.Ongoing: // 開催中
      case RankingEventStatus.AnnouncingResults: // 結果発表中
        break // 以下でrendering
      default:
        return <></>
    }

    const scrollRankingAnchor = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      const anchor = document.querySelector('.ranking-anchor')
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const isBoosting = (): boolean => {
      return boostRetio() > 1
    }

    const boostRetio = (): number => {
      return rankingEvent.joiningEvent?.ranking_event?.active_ranking_yell_boost?.boost_ratio || 1
    }

    const countableMaxYell = (): number => {
      return rankingEvent.YellCount.ClickMax * boostRetio()
    }

    // 開催中、結果発表のレンダリング
    return (
      <StyledRankingEventContainer>
        {
          // 結果発表中の表示 （結果発表中のみ表示）
          status == RankingEventStatus.AnnouncingResults && (
            <Link
              href={`/ranking/event/${rankingEvent.joiningEvent?.ranking_event.event_identity}/info`}
            >
              <StyledEventResultImage
                src={`${publicRuntimeConfig.PUBLIC_BLOB_URL}/ranking_event/${rankingEvent.joiningEvent?.ranking_event.event_identity}/result-banner.webp`}
                alt={joiningEvent.ranking_event.name}
              />
            </Link>
          )
        }

        {
          // ランキング順位とエール数の表示 （開催中のみ表示）
          status == RankingEventStatus.Ongoing && (
            <StyledCreatorRankingInfo>
              <StyledEventTitleImageWraper>
                <Link
                  href={rankingEvent.generateRouteWithViewCreator(
                    `/ranking/event/${joiningEvent.ranking_event.event_identity}/info`,
                    `${router.query.creator_id}`,
                  )}
                >
                  <StyledEventTitleImage
                    src={`${publicRuntimeConfig.PUBLIC_BLOB_URL}/ranking_event/${rankingEvent.joiningEvent?.ranking_event.event_identity}/event-title.webp`}
                  />
                </Link>
              </StyledEventTitleImageWraper>
              <RankCrown style={{ height: '24px' }} />
              <StyledRankingTextSpan style={{ marginRight: '24px' }}>
                {rankingEvent?.joiningEvent?.yell_count === 0
                  ? '-'
                  : rankingEvent?.joiningEvent?.rank
                  ? joiningEvent.rank.toLocaleString() + ' 位'
                  : '-'}
              </StyledRankingTextSpan>
              <img src="/ranking/yell_icon.svg" style={{ height: '24px' }} />
              <StyledRankingTextSpan>
                {rankingEvent?.joiningEvent?.yell_count
                  ? joiningEvent.yell_count.toLocaleString()
                  : '-'}
              </StyledRankingTextSpan>
            </StyledCreatorRankingInfo>
          )
        }

        {
          // エール送信ボタンの表示 （開催中のみ表示）
          status == RankingEventStatus.Ongoing && (
            <>
              {isBoosting() && (
                <StyledBoostTitleImage src="/ranking/boost/yell_boost_title.webp"></StyledBoostTitleImage>
              )}
              <StyledButtonsWrapper>
                {/* エールを送るボタン */}
                {yellEmojis.map((emoji, index) => (
                  <StyledFloatImage
                    src={`/ranking/yell/${emoji.symbol}`}
                    fileName={emoji.symbol}
                    key={index}
                    style={{ left: emoji.x, top: emoji.y }}
                  ></StyledFloatImage>
                ))}
                {sparklingIcons.map((icon, index) => (
                  <StyledSparklingIcon key={index} style={{ left: icon.x, top: icon.y }}>
                    {icon.symbol}
                  </StyledSparklingIcon>
                ))}
                {rankingEvent.actionable?.can_yell_for_click && (
                  <StyledYellCount>
                    {yellClickCount || 0}/{countableMaxYell()}
                  </StyledYellCount>
                )}
                {rankingEvent.actionable?.can_yell_for_click ? (
                  <StyledPostYellButtonImage
                    ref={buttonPostYellRef}
                    src={
                      isBoosting()
                        ? '/ranking/boost/post_yell_button.webp'
                        : '/ranking/post_yell_button.webp'
                    }
                    alt="post yell button"
                    onClick={(event: React.MouseEvent) => {
                      // ログインしてない場合はログインへ遷移
                      if (!loginUserData) {
                        Router.push(
                          `${publicRuntimeConfig.AUTH_URL}?return_url=${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`,
                        )
                        return
                      }
                      // エールポスト処理実行
                      handlePostYell(event)
                    }}
                    isClicked={isPostYellClicked}
                  />
                ) : rankingEvent.actionable?.today_yell_count &&
                  rankingEvent.actionable?.today_yell_count >= countableMaxYell() ? (
                  <StyledPostYellButtonDisableImage
                    src="/ranking/post_yell_button_completed.svg"
                    alt="post yell button"
                    style={{ paddingLeft: '3%', paddingRight: '3%', paddingBottom: '3%' }}
                  />
                ) : (
                  <StyledCircleLoading></StyledCircleLoading>
                )}

                {/* エールガチャボタン */}
                {rankingEvent.actionable ? (
                  rankingEvent.actionable?.can_yell_for_gacha ? (
                    <StyledGachaYellButtonImage
                      src="/ranking/gacha_yell_button.webp"
                      onClick={(event: React.MouseEvent) => {
                        // ログインしてない場合はログインへ遷移
                        if (!loginUserData) {
                          Router.push(
                            `${publicRuntimeConfig.AUTH_URL}?return_url=${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`,
                          )
                          return
                        }
                        // エールガチャ処理実行
                        handleGachaYell(event)
                      }}
                    />
                  ) : (
                    <StyledGachaYellButtonDisableImage src="/ranking/gacha_yell_button_disable.webp" />
                  )
                ) : (
                  <StyledCircleLoading></StyledCircleLoading>
                )}
              </StyledButtonsWrapper>
            </>
          )
        }

        {
          // エール送付郡ボタンの表示 （開催中のみ表示）
          status == RankingEventStatus.Ongoing && (
            <StyledCreatorRankingButtonsContainer>
              <div className="message">
                {!isBoosting() ? (
                  <>
                    <span>更に応援しよう!</span>
                    <img
                      src="/ranking/yell_comment_icon.webp"
                      alt="yell comment icon button"
                      className="yell-comment"
                    />
                  </>
                ) : (
                  <>
                    <span className="boosting">こちらもエール{boostRetio()}倍中!</span>
                    <img
                      src="/ranking/boost/yell_comment_icon.webp"
                      alt="yell comment icon button"
                      className="yell-comment"
                    />
                  </>
                )}
              </div>
              <StyledCreatorRankingButtons className="buttons">
                {
                  // シェアボタン （開催中のみ表示）
                  rankingEvent.actionable?.can_yell_for_share ? (
                    <TwitterShareButton
                      ref={twitterShareButtonRef}
                      url={`${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`}
                      via={`FANME__official`}
                      title={`🌟ランキングイベント開催中🌟\n「${creatorData?.name}」さんを応援しにいこう！`}
                      hashtags={
                        rankingEvent.joiningEvent?.ranking_event.share_hashtags
                          ?.split(',')
                          .map(tag => `${tag}\n`) || []
                      }
                      onClick={async () => {
                        // ログインしてない場合はログインへ遷移
                        if (!loginUserData) {
                          Router.push(
                            `${publicRuntimeConfig.AUTH_URL}?return_url=${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`,
                          )
                          return
                        }
                        // ログインしている場合のみエール付与
                        if (!rankingEvent.joiningEvent) return
                        const event_identity =
                          rankingEvent.joiningEvent.ranking_event.event_identity
                        const creator_id = `@${rankingEvent.joiningEvent.creator.account_identity}`
                        await rankingEvent.postYell(
                          event_identity,
                          creator_id,
                          rankingEvent.YellLogType.Share,
                          rankingEvent.YellCount.Share,
                        )
                        rankingEvent.getActionable(event_identity, creator_id)
                      }}
                    >
                      <StyledYellButtonImage
                        src="/ranking/share_ranking_button.webp"
                        alt="share ranking button"
                      />
                    </TwitterShareButton>
                  ) : (
                    <StyledYellButtonImage
                      src="/ranking/share_ranking_button.webp"
                      alt="share ranking button"
                      style={{ opacity: 0.5 }}
                    />
                  )
                }
                <a href="#" onClick={scrollRankingAnchor}>
                  <StyledYellButtonImage
                    src="/ranking/purchase_ranking_button.webp"
                    alt="purchase ranking button"
                  />
                </a>
                {fanLetterUrl && (
                  <StyledYellButtonImage
                    ref={fanLetterButtonRef}
                    src="/ranking/fan_letter_ranking_button.webp"
                    alt="fan letter ranking button"
                    onClick={() => {
                      Router.push(fanLetterUrl)
                    }}
                  />
                )}
              </StyledCreatorRankingButtons>
            </StyledCreatorRankingButtonsContainer>
          )
        }

        {/* ランキングリンク郡の表示 */}
        <StyledCreatorRankingButtons>
          {
            // クリエイターランキング ボタン
            <Link
              href={rankingEvent.generateRouteWithViewCreator(
                `/ranking/event/${joiningEvent.ranking_event.event_identity}/creators`,
                `${router.query.creator_id}`,
              )}
            >
              <StyledRankingButtonImage
                src="/ranking/creator_ranking_button.webp"
                alt="fan ranking button"
                style={{ margin: '0 12px 0 0' }}
              />
            </Link>
          }

          {
            //ファンランキング ボタン
            <Link
              href={rankingEvent.generateRouteWithViewCreator(
                `/ranking/event/${joiningEvent.ranking_event.event_identity}/creator/${router.query.creator_id}/fans`,
                `${router.query.creator_id}`,
              )}
            >
              <StyledRankingButtonImage
                src="/ranking/fan_ranking_button.webp"
                alt="fan ranking button"
              />
            </Link>
          }
        </StyledCreatorRankingButtons>

        {
          // イベントポップアップ
          <StyledPopupModalContainer>
            <PopUpModal
              visible={isCurrentPopup && visibleEventPopUp}
              setVisible={handlePopupVisibleChanged}
              popUpData={{
                enable: true,
                url: rankingEvent.generateRouteWithViewCreator(
                  `/ranking/event/${joiningEvent.ranking_event.event_identity}/info`,
                  `${router.query.creator_id}`,
                ),
                button_text: 'イベント詳細はこちら',
                title: `「${joiningEvent.ranking_event.name}」を実施しています！`,
                image: joiningEvent.ranking_event.image_url,
                updated_at: new Date().toLocaleDateString(),
              }}
            />
          </StyledPopupModalContainer>
        }
      </StyledRankingEventContainer>
    )
  },
)

RankingAreaForCreator.displayName = 'RankingAreaForCreator'
export default RankingAreaForCreator
