import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment/moment'
import 'moment/locale/ja'
import { Colors } from '@/constants/styles/color'
import { IRankingYellBoost } from '@/contexts/RankingEventContext'

const StyledContainer = styled.div`
  position: absolute;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
  display: flex;
`

const StyledAlertBG = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledAlert = styled.div`
  display: flex;
  position: relative;
  background: ${Colors.WHITE};
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  width: calc(100% - 80px);
  z-index: 10003;
  padding: 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

const StyledMain = styled.div`
  margin: 8px;
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

const StyledPopupImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 480px;
`
const StyledPopupPeriod = styled.div`
  font-size: 13px;
  padding: 10px;
  background: #b53599;
  text-align: center;
  span.title {
    padding: 0 8px;
    background: ${Colors.WHITE};
    color: #b53599;
  }
  span.period {
    padding: 0 8px;
    color: ${Colors.WHITE};
  }
`

const StyledCreatorRankingButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 12px 0 16px 0;
`

const StyledYellButtonImage = styled.img`
  height: 80px;
`

interface Props {
  visible: boolean
  ranking_yell_boost: IRankingYellBoost
  onButtonClose?: () => void
  onShare?: () => void
  onFanLetter?: () => void
}

const RankingBoostingDialog = ({
  visible,
  ranking_yell_boost,
  onButtonClose,
  onShare,
  onFanLetter,
}: Props) => {
  if (!visible) return <></>
  moment.locale('ja')

  return (
    <>
      <StyledContainer>
        <StyledAlertBG />
        <StyledAlert>
          <StyledMain>
            <StyledPopupImage
              src="/ranking/boost/yell_boost_discription.webp"
              alt="yell boost discription"
            />
            <StyledPopupPeriod>
              <span className="title">ブースト期間</span>
              <span className="period">
                {moment(ranking_yell_boost.start_at).format('M/D(ddd) H:mm')}〜
                {moment(ranking_yell_boost.end_at).format('H:mm')}
              </span>
            </StyledPopupPeriod>
          </StyledMain>

          <StyledCreatorRankingButtons>
            <StyledYellButtonImage
              src="/ranking/share_ranking_button.webp"
              alt="share ranking button"
              onClick={onShare}
            />
            <StyledYellButtonImage
              src="/ranking/fan_letter_ranking_button.webp"
              alt="fan letter ranking button"
              onClick={onFanLetter}
            />
          </StyledCreatorRankingButtons>

          {onButtonClose && (
            <StyledCloseButtonContainer
              onClick={() => {
                onButtonClose()
              }}
            >
              閉じる
            </StyledCloseButtonContainer>
          )}
        </StyledAlert>
      </StyledContainer>
    </>
  )
}

export default RankingBoostingDialog
