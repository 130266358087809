import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import { Colors } from '@/fanmeUi/constants/style'
import { StylePreviewContent } from '../../AddHeadLineDetail/Modal/Styles/HeadLineStyle'
import { StyledListContents } from '../index.style'

const StyleMargin = styled.div<{ contentMargin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: ${p => p.contentMargin ?? ''};
`
type ContentMixin = {
  borderColor?: string
  borderRadius?: string
  backgroundColor?: string
  contentMargin?: string
  id?: string
}
interface ContentStyle {
  style?: ContentMixin
  icon?: string
  title?: string
}
interface MarginProps {
  content: ContentStyle
}
//mainに表示する
const Margin = ({ content }: MarginProps) => {
  const { ref, inView } = useInView()
  const [mainContent, setMainContent] = useState<ContentMixin>({})

  const setMainContentWithDefault = (style: ContentStyle) => {
    const { backgroundColor, contentMargin, id }: ContentMixin = style.style || {}
    setMainContent({
      backgroundColor,
      contentMargin,
      id,
    })
  }

  const initializeMainContent = () => {
    setMainContent(prev => ({
      ...prev,
      borderRadius: '16px',
      backgroundColor: Colors.BK_GRAY,
    }))
  }

  useEffect(() => {
    if (content.icon && content.style === null && content.title === '') {
      initializeMainContent()
    } else {
      setMainContentWithDefault(content)
    }
  }, [content])

  const { contentMargin, id } = mainContent

  return (
    <StyledListContents ref={ref} inView={inView}>
      <StylePreviewContent>
        <StyleMargin contentMargin={contentMargin} id={id}></StyleMargin>
      </StylePreviewContent>
    </StyledListContents>
  )
}

export default Margin
