import React, { useEffect, useState } from 'react'
import { useModal } from '@nextui-org/react'
import { useRouter } from 'next/router'
import HalfRoundedButton from '@/components/atoms/HalfRoundedButton'
import SideBar from '@/components/molecules/SideBar'

import Menu from '@/assets/img/icons/Menu.svg'
import { Colors } from '@/constants/styles/color'

const LoginMenuButton = () => {
  const { setVisible, visible } = useModal()
  const { asPath } = useRouter()

  const [windowWidth, setWindowWidth] = useState(0)
  useEffect(() => {
    // サイドメニューの位置を調整するため
    const deviceWidth = window.innerWidth
    if (deviceWidth) setWindowWidth(window.innerWidth)
  }, [visible])

  return (
    <>
      <HalfRoundedButton
        size={'sm'}
        icon={<Menu />}
        isRight={false}
        onClick={() => setVisible(true)}
      />
      <div
        style={{
          background: Colors.PURE_BLACK,
          opacity: 0.3,
          position: 'fixed',
          zIndex: 1,
          top: 0,
          left: windowWidth > 480 ? 'calc((100vw - 480px) / 2)' : 0,
          transform: visible ? 'translateX(0)' : 'translateX(100vw)',
          overflowX: 'hidden',
          width: '100%',
          maxWidth: '480px',
          height: '100vh',
        }}
        onClick={() => setVisible(false)}
      />
      <SideBar
        windowWidth={windowWidth}
        visible={visible}
        setVisible={setVisible}
        currentPath={asPath}
      />
    </>
  )
}

export default LoginMenuButton
