import { Button, Container, Text } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
import { FacebookShareButton, LineShareButton, TwitterShareButton } from 'react-share'
import styled from '@emotion/styled'
import copy from 'copy-to-clipboard'
import { Colors } from '@/constants/styles/color'

import ArrowBack from '@/assets/img/icons/Arrow_Back.svg'
import CloseM from '@/assets/img/icons/Close_M.svg'
import Copy from '@/assets/img/icons/Copy.svg'
import Other from '@/assets/img/icons/Other.svg'

import TwitterIcons from '@/components/atoms/Icon/sns/TwitterIcons'
import LineIcons from '@/components/atoms/Icon/sns/LineIcons'
import FacebookIcons from '@/components/atoms/Icon/sns/FacebookIcons'
import ItemNotification from '@/components/molecules/Notification/ItemNotification'
import { FmeSpace } from '@/fanmeUi/shared/base'

/* QRコードはNodeCanvasによってレンダリングされるので、サーバサイドはサポートしていません */
const CreatorQrCode = dynamic(() => import('../CreatorQrCode'), { ssr: false })

const QrDescriptionText = styled.div`
  font-size: 11px;
  color: ${Colors.BLACK};
  text-align: center;
`

interface Props {
  visible: boolean
  onClose: () => void
  accountIdentity: string
  creatorName: string
  qrCodeConfig: QrCodeConfig
}

const { publicRuntimeConfig } = getConfig()

const SharePopup = ({ visible, onClose, qrCodeConfig, accountIdentity, creatorName }: Props) => {
  const shareLink = `${publicRuntimeConfig.FRONT_URL}/@${accountIdentity}`
  const shareTitle = creatorName
  const shareDescription =
    'FANME(ファンミー)は、クリエイター向けの無料のプロフィールまとめサービスです。'
  const shareHashtags = ['FANME', 'ファンミー', 'クリエイター', 'プロフ', 'まとめ', 'リンク']
  const shareHashtag = '#FANME'

  const [notifyVisible, setNotifyVisible] = useState(false)

  // クリップボードにコピー
  const copyLink = () => {
    setNotifyVisible(true)
    copy(shareLink)
    setTimeout(() => {
      setNotifyVisible(false)
    }, 1000)
  }

  // シェアその他を表示する
  const shareOthers = async () => {
    try {
      await navigator.share({
        title: shareTitle,
        text: shareDescription,
        url: shareLink,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const [sharePopupBottom, setSharePopupBottom] = useState(0)
  useEffect(() => {
    if (visible) {
      const elemSharePopup = document.getElementById('sharePopup')
      if (elemSharePopup) {
        const bottom = elemSharePopup.clientHeight - window.innerHeight
        setSharePopupBottom(bottom)
      }
    }
  }, [visible])

  if (!visible) return <></>

  return (
    <>
      <Container
        id="sharePopup"
        css={{
          h: '100vh',
          mw: '480px',
          position: 'fixed',
          left: 'auto',
          top: 0,
          zIndex: 100,
          p: 0,
        }}
      >
        <div
          style={{
            background: '#00000060',
            width: '100%',
            height: '100%',
          }}
          onClick={onClose}
        />
        <Container
          css={{
            position: 'absolute',
            bottom: 'env(safe-area-inset-bottom)',
            w: '100%',
            padding: `0 0 ${sharePopupBottom}px 0`,
            background: Colors.WHITE,
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
          }}
        >
          <Container
            css={{
              d: 'flex',
              h: '48px',
              display: 'grid',
              background: Colors.BLACK,
              gridTemplateColumns: '1fr 16px',
              paddingLeft: '16px',
              paddingRight: '16px',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
            }}
          >
            <ArrowBack
              fill={Colors.BLACK}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
            <Text
              color={Colors.WHITE}
              size={16}
              css={{
                mt: 'auto',
                mb: 'auto',
                fontFamily: 'Noto Sans JP',
                fontStyle: 'normal',
                fontWeight: '600',
                lh: '16px',
                letterSpacing: '0.2em',
                display: 'flex',
                ai: 'center',
                ta: 'center',
              }}
            >
              SHARE
            </Text>
            <CloseM
              width={14}
              height={14}
              stroke={Colors.WHITE}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              onClick={onClose}
            />
          </Container>

          {/** TODO: デザインシステムに移行 **/}
          <FmeSpace size={2} />
          <CreatorQrCode config={qrCodeConfig} content={shareLink} />
          <FmeSpace size={0.5} />
          <QrDescriptionText>
            画像からページを開く方法は
            <a href={`https://note.com/fanme_official/n/nc02789293e23`} target={`_blank`}>
              こちら
            </a>
          </QrDescriptionText>

          <Container
            css={{
              m: '24px 0',
              w: '100%',
              h: 'calc(100% - 150px)',
            }}
          >
            <div
              style={{
                backgroundColor: Colors.WHITE,
                filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))',
                borderRadius: '20px',
                margin: '0 auto',
                padding: '0px 12px 0px 16px',
                height: '40px',
                display: 'grid',
                gridTemplateColumns: '1fr 16px',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  minHeight: '16px',
                  display: 'flex',
                }}
              >
                <Text
                  css={{
                    color: Colors.BLACK,
                    m: '0',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      fontFamily: 'Noto Sans JP',
                      fontWeight: '400',
                      fontSize: '10px',
                      lineHeight: '10px',
                      margin: '0 4px 0 0',
                    }}
                  >
                    https://fanme.link/
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontFamily: 'Avenir Next',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '14px',
                    }}
                  >
                    @{accountIdentity}
                  </span>
                </Text>
              </div>
              <Button
                onClick={copyLink}
                css={{
                  p: '0',
                  minWidth: '16px',
                  d: 'flex',
                  bc: 'transparent',
                  br: '50%',
                }}
                icon={<Copy style={{ width: '16px', height: '16px' }} />}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '312px',
                margin: '32px auto 0',
              }}
            >
              <TwitterShareButton url={shareLink} title={shareTitle} hashtags={shareHashtags}>
                <TwitterIcons type="r_bk" />
              </TwitterShareButton>
              <LineShareButton url={shareLink} title={shareTitle}>
                <LineIcons type="r_bk" />
              </LineShareButton>
              <FacebookShareButton url={shareLink} hashtag={shareHashtag}>
                <FacebookIcons type="r_bk" />
              </FacebookShareButton>
              <div
                onClick={shareOthers}
                className="min-w-12 h-12 rounded-full flex items-center justify-center bg-BLACK"
              >
                <Other
                  fill={Colors.WHITE}
                  style={{
                    width: '15.43px',
                    height: '15.43px',
                  }}
                />
              </div>
            </div>
          </Container>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              top: '-32px',
              width: '100%',
            }}
          >
            {notifyVisible && <ItemNotification text={'リンクをコピーしました'} />}
          </div>
        </Container>
      </Container>
    </>
  )
}

export default SharePopup
