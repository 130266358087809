import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const StyledBalloonContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3);
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  pointer-events: none; /* 背景のスクロールを有効にする */
`

const balloonAnimation = keyframes`
  0% {
    bottom: -30%;
  }
  40% {
    bottom: -30%;
  }
  100% {
    bottom: 110%;
  }
`

const StyledFloatImage = styled.img<{
  width: number
  left: number
  duration: number
  delay: number
}>`
  bottom: 110%;
  position: absolute;
  width: ${({ width }) => width}px;
  left: ${({ left }) => left}%;
  animation: ${balloonAnimation} ${({ duration }) => duration}s linear ${({ delay }) => delay}s;
`

const BalloonAction = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [balloonStyles] = useState(
    Array.from({ length: 50 }).map(() => ({
      colorNumber: Math.floor(Math.random() * 5) + 1,
      width: 130 * (0.7 + Math.random() * 0.6),
      left: -15 + Math.random() * 110,
      duration: 1.5 + Math.random() * 1.5,
      delay: Math.random() * 1,
    })),
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 3500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <StyledBalloonContainer isVisible={isVisible}>
      {balloonStyles.map((style, index) => (
        <StyledFloatImage
          key={index}
          src={`/general_event/balloon/balloon_${style.colorNumber}.webp`}
          width={style.width}
          left={style.left}
          duration={style.duration}
          delay={style.delay}
        />
      ))}
    </StyledBalloonContainer>
  )
}

export default BalloonAction
