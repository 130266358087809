import React, { useState } from 'react'
import { Image, Text } from '@nextui-org/react'

import Official from '@/assets/img/icons/Official.svg'
import Login from '@/assets/img/icons/Login.svg'
import Share from '@/assets/img/icons/Share.svg'

import { Colors } from '@/constants/styles/color'

type Props = {
  creatorName: string
  officialFlag: boolean | undefined
  avatarImg?: string
  statusDisplay: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onClickShare: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const CreatorStatusButton = ({
  creatorName,
  officialFlag,
  avatarImg,
  statusDisplay,
  onClick,
  onClickShare,
}: Props) => {
  const [avatar, setAvatar] = useState<string | undefined>(avatarImg)

  return (
    <>
      {!statusDisplay ? (
        <div style={{ width: '65%', height: '40px', position: 'absolute' }} />
      ) : (
        <div
          style={{
            width: '65%',
            background: Colors.WHITE,
            borderRadius: '20px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
            height: '40px',
            display: 'grid',
            gridTemplateColumns: '1fr 40px',
            alignItems: 'center',
          }}
        >
          <div
            onClick={onClick}
            style={{
              display: 'grid',
              alignItems: 'center',
              gridTemplateColumns: '40px 1fr',
            }}
          >
            {!avatar ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  background: Colors.LIGHT_GRAY,
                  width: '34px',
                  height: '34px',
                  padding: 0,
                  margin: '3px',
                }}
              >
                <Login fill={Colors.BLACK} style={{ width: '18px', height: '18px' }} />
              </div>
            ) : (
              <div style={{ margin: '3px' }}>
                <Image
                  src={avatar}
                  alt=""
                  width={34}
                  height={34}
                  css={{ borderRadius: '50%' }}
                  onError={() => setAvatar(undefined)}
                />
              </div>
            )}

            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                margin: '0 0 0 6px',
              }}
            >
              <Text
                size={11}
                css={{
                  m: '0',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lh: '11px',
                  letterSpacing: '0em',
                  color: Colors.BLACK,
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                  ta: 'left',
                  overflow: 'hidden',
                  d: '-webkit-box',
                  webkitBoxOrient: 'vertical',
                  webkitLineClamp: '2',
                }}
              >
                {creatorName}
              </Text>
              <div>{officialFlag && <Official className={'ml-1.5 inline-block'} />}</div>
            </div>
          </div>
          <div
            style={{
              borderRadius: '50%',
              height: '24px',
              width: '24px',
              minWidth: '24px',
              minHeight: '24px',
              background: Colors.TRANSPARENT,
              zIndex: '0',
              padding: '0',
              margin: '0 auto',
            }}
            onClick={onClickShare}
          >
            <Share style={{ width: '24px', height: '24px', fill: Colors.BLACK }} />
          </div>
        </div>
      )}
    </>
  )
}

export default CreatorStatusButton
