import React, { FC } from 'react'
import Link from 'next/link'
import { Colors } from '@/constants/styles/color'

import { FmeButton } from '@/fanmeUi/elements/button'

import { useIsMounted } from '@/utils/hooks/is_mounted'

import { followCreator, unfollowCreator } from '@/pages/api/payment'

type Props = {
  isPreview: boolean | null | undefined
  following: boolean
  setFollow: (status: boolean) => void
  link: string
  isDark: boolean
  uid?: string
}
const FollowButton: FC<Props> = ({ isPreview, following, link, isDark, uid = '', setFollow }) => {
  const isMounted = useIsMounted()
  const handleClick = async () => {
    if (following) {
      try {
        const res = await unfollowCreator(uid)
        if (res.data === 'success') {
          if (isMounted()) setFollow(false)
        } else {
          if (isMounted()) setFollow(true)
        }
      } catch (error) {
        if (isMounted()) setFollow(true)
        console.error(error)
      }
    } else {
      try {
        const res = await followCreator(uid)
        if (res.data === 'success') {
          if (isMounted()) setFollow(true)
        } else {
          if (isMounted()) setFollow(false)
        }
      } catch (error) {
        if (isMounted()) setFollow(false)
        console.error(error)
      }
    }
  }
  return (
    <>
      {isPreview ? (
        <Link
          href={link}
          style={{
            fontSize: '12px',
            color: isDark ? Colors.WHITE : Colors.PRIMARY_GRAY,
            fontWeight: 300,
            textDecoration: 'underline',
          }}
        >
          フォロー中
        </Link>
      ) : (
        isPreview !== null && (
          <div style={{ width: 100 }}>
            <FmeButton
              buttonSize={'small'}
              buttonStyle={`${following === false ? 'primary' : 'black-outline'}`}
              onClick={async () => handleClick()}
            >
              {following === true ? 'フォロー中' : 'フォロー'}
            </FmeButton>
          </div>
        )
      )}
    </>
  )
}

export default FollowButton
