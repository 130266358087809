import React from 'react'
import { useInView } from 'react-intersection-observer'

import SquareContents from '@/components/molecules/SquareContents'
import { StyledContents } from '../index.style'

interface Props {
  contents: Array<{
    id: number
    url?: string
    icon?: string
    title?: string
  }>
}

const FourBlock = ({ contents }: Props) => {
  const { ref, inView } = useInView()

  return (
    <StyledContents ref={ref} inView={inView}>
      {contents.map((content, i) => {
        return (
          <SquareContents
            id={content.id}
            size="xs"
            link={content.url || content.icon}
            isBoxShadow={content?.url ? true : false}
            title={content.title}
            imageSrc={content.icon}
            key={`fourBlock${i}`}
          />
        )
      })}
    </StyledContents>
  )
}

export default FourBlock
