import React from 'react'
import { NextPage, NextPageContext } from 'next'
import PageNotFound from '@/components/templates/Errors/PageNotFound'
import ServerError from '@/components/templates/Errors/ServerError'

type Props = {
  statusCode?: number
}

const ErrorPage: NextPage<Props> = ({ statusCode }) =>
  statusCode === 404 ? <PageNotFound /> : <ServerError />

ErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default ErrorPage
