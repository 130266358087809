import React from 'react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { Colors } from '@/constants/styles/color'
import ToCreatorButton from '@/assets/img/ranking/to_creator_button.svg'

const StyledContainer = styled.div`
  position: absolute;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
  display: flex;
`

const StyledAlertBG = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledAlert = styled.div`
  display: flex;
  position: relative;
  background: ${Colors.WHITE};
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  width: calc(100% - 80px);
  z-index: 10003;
  padding: 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

const StyledMain = styled.div`
  margin: 0;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 14px;
`

const StyledUserContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`

const StyledCreatorImage = styled.img`
  width: 34px;
  border-radius: 50%;
  top: 3px;
  right: 5px;
  background-color: white;
`

const StyledCreatorName = styled.div`
  margin-left: 8px;
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

const StyledPopupImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 480px;
  border-radius: 0 0 16px 16px;
  cursor: pointer;
`

const ToCreatorButtonContainer = styled.button`
  border: none;
  font-size: 14px;
  cursor: pointer;
`

interface Props {
  visible: boolean
  isGeneralEvent?: boolean
  imageSrc: string
  eventId: string | number
  creatorImageSrc: string
  creatorName: string
  creatorId: string
  onButtonClose?: () => void
  onImageClick?: () => void
}

const RankingBadgeTitleDialog = ({
  visible,
  isGeneralEvent,
  imageSrc,
  eventId,
  creatorImageSrc,
  creatorName,
  creatorId,
  onButtonClose,
  onImageClick,
}: Props) => {
  const router = useRouter()

  if (!visible) return <></>

  console.log('isGeneralEvent', isGeneralEvent)
  const fansUrl = isGeneralEvent
    ? `/@${creatorId}/event/${eventId}/fans`
    : `/ranking/event/${eventId}/creator/@${creatorId}/fans`

  return (
    <>
      <StyledContainer>
        <StyledAlertBG />
        <StyledAlert>
          <StyledMain>
            {creatorName && (
              <StyledHeaderContainer onClick={() => router.push(`/@${creatorId}`)}>
                <StyledUserContainer>
                  <StyledCreatorImage src={creatorImageSrc} alt="creator" />
                  <StyledCreatorName>{creatorName}</StyledCreatorName>
                </StyledUserContainer>
                <ToCreatorButtonContainer>
                  <ToCreatorButton />
                </ToCreatorButtonContainer>
              </StyledHeaderContainer>
            )}
            <StyledPopupImage
              src={imageSrc}
              alt="title image"
              onClick={() => {
                if (router.asPath === fansUrl) {
                  // 同じページの場合はダイアログは閉じる
                  onButtonClose && onButtonClose()
                } else {
                  router.push(fansUrl)
                }
              }}
            />
          </StyledMain>

          {onButtonClose && (
            <StyledCloseButtonContainer
              onClick={() => {
                onButtonClose()
              }}
            >
              閉じる
            </StyledCloseButtonContainer>
          )}
        </StyledAlert>
      </StyledContainer>
    </>
  )
}

export default RankingBadgeTitleDialog
