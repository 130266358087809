import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

const StyledParticle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ParticleRouletteSpinning = () => {
  const appendAll = (parent: Element, elems: Element[]) => {
    if (!parent) return
    elems.forEach(elem => parent.appendChild(elem))
  }

  const removeAll = (elems: Element[]) => {
    elems.forEach(elem => elem.parentNode?.removeChild(elem))
  }

  const createElementsWithClass = (count: number, tagName = 'div', className = '') => {
    return Array(count)
      .fill(0)
      .map(() => {
        const elem = document.createElement(tagName)
        elem.className = className
        return elem
      })
  }

  const emitParticles = async (particleCount = 10) => {
    const center = document.querySelector(`#particle .center`)!
    const dots = createElementsWithClass(particleCount, 'div', 'dot')
    appendAll(center, dots) // 画面に表示

    const animations = dots.map(dot => {
      const angle = 360 * Math.random()
      const dist = Math.min(window.innerWidth, 480) / 2 + 10 * Math.random()
      const size = 2 + Math.random() * 1.5
      const hue = 60 + Math.random() * 25
      dot.style.backgroundColor = `hsl(${hue}, 90%, 60%)`
      dot.style.zIndex = '-1'
      dot.style.position = 'absolute'
      dot.style.width = '10px'
      dot.style.height = '5px'
      dot.style.borderRadius = '10px'
      dot.style.transition = 'all .5s'
      dot.style.opacity = '1'
      dot.style.backgroundColor = 'white'

      const hasBlendmode = Math.random() > 0.5
      if (hasBlendmode) {
        dot.style.mixBlendMode = 'add'
      }

      return dot.animate(
        [
          {
            transform: `rotate(${angle}deg) translateX(0px) scale(${size})`,
            opacity: 0.9,
          },
          {
            transform: `rotate(${angle}deg) translateX(${dist * 0.9}px) scale(${size})`,
            opacity: 0.8,
          },
          {
            transform: `rotate(${angle}deg) translateX(${dist}px) scale(${size})`,
            opacity: 0,
          },
        ],
        {
          duration: 2000 * Math.random(),
          fill: 'forwards',
        },
      )
    })

    // 全てのアニメーションが終わるまで待つ
    await Promise.all(animations.map(anim => anim.finished))
    removeAll(dots) // 削除
  }

  useEffect(() => {
    const emitParticlesMultipleTimes = async () => {
      for (let i = 0; i < 4; i++) {
        if (i > 0) emitParticles(10 - i)
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
    }

    emitParticlesMultipleTimes()
  }, [])

  return (
    <StyledParticle id="particle">
      <div className="center"></div>
    </StyledParticle>
  )
}

export default ParticleRouletteSpinning
