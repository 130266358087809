import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledRound = styled.div<{ num?: string }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Colors.WHITE};

  &::before {
    content: ${props => (props.num ? `'${props.num}'` : '""')};
    display: ${props => (Number(props.num) !== 0 ? `block` : 'none')};
    min-width: 14px;
    height: 14px;
    padding: 0 2px;
    line-height: 14px;
    border-radius: ${props => (!!props.num && props.num.length >= 2 ? '7px' : '50%')};
    background-color: ${Colors.ERROR};
    position: absolute;
    right: 0;
    top: -5px;
    color: ${Colors.WHITE};
    text-align: center;
    font-size: 9px;
  }
`

export { StyledRound }
