import { Container, Row } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import ReadMoreButton from '@/components/molecules/ReadMoreButton'
import { Colors } from '@/constants/styles/color'
import { useThemeContext } from '@/contexts/ThemeContext'

interface Props {
  message?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreatorMessage = ({ message }: Props) => {
  const { isDark, color } = useThemeContext()

  // READMOREの表示切り替え
  const [showReadMore, setShowReadMore] = useState(true)
  const handleClickReadMore = () => setShowReadMore(!showReadMore)

  useEffect(() => {
    // テキストが5行以上だった場合、隠しREADMOREボタンを表示する
    const commentElem = document.getElementById('comment')
    // テキスト要素の高さを取得
    const textHeight = Number(commentElem ? commentElem.clientHeight : '0')
    // テキスト要素のline-heightを取得
    let lineHeight = commentElem
      ? getComputedStyle(commentElem).getPropertyValue('line-height')
      : '0'
    // ピクセル値が返ってくるので、数字だけにする
    lineHeight = lineHeight.replace(/[^-\d.]/g, '')

    // メニューが5行以上だったら高さを5行にする
    if (textHeight >= Number(lineHeight) * 5) {
      setShowReadMore(false)
    }
  }, [message])

  // ぼかしのグラデーションのために、カラーコードをrgbaに変換
  const convertColorCode2Rgba = (a: number) => {
    const red = parseInt(color.substring(1, 3), 16)
    const green = parseInt(color.substring(3, 5), 16)
    const blue = parseInt(color.substring(5, 7), 16)
    return `rgba(${red}, ${green}, ${blue}, ${a})`
  }

  if (!message) return <></>
  return (
    <>
      <Container>
        <Row
          id="comment"
          className="comment"
          style={{
            fontWeight: '400',
            fontSize: '11px',
            lineHeight: '17px',
            justifyContent: 'center',
            textAlign: 'center',
            position: 'relative',
            overflow: !showReadMore ? 'hidden' : 'visible',
            height: !showReadMore ? '48px' : 'auto',
            marginTop: '4px',
            marginBottom: '12px',
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            color: isDark ? Colors.WHITE : Colors.BLACK,
          }}
          css={{
            '&::before': {
              display: `${showReadMore ? 'none' : 'block'}`,
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '24px',
              textAlign: 'center',
              background: `linear-gradient(180deg, ${convertColorCode2Rgba(
                0.99,
              )} 0%, ${convertColorCode2Rgba(0)} 100%)`,
              content: '""',
              transform: 'rotate(-180deg)',
            },
          }}
        >
          {message}
        </Row>
        {!showReadMore && <ReadMoreButton onClick={handleClickReadMore} />}
      </Container>
    </>
  )
}

export default CreatorMessage
