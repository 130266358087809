import React, { useEffect, useState } from 'react'
import { StyledRound } from './index.style'

interface IFloatIconProps {
  children: React.ReactNode
  cls?: string
  newMsgs?: string
  onClick?: () => void
}
const FloatIcon = ({ cls, children, newMsgs, onClick }: IFloatIconProps) => {
  return (
    <StyledRound
      num={newMsgs}
      className={`${cls} flex justify-center items-center`}
      onClick={onClick}
    >
      {children}
    </StyledRound>
  )
}

export default FloatIcon
