import React from 'react'
import { useInView } from 'react-intersection-observer'

import VideoLine from '@/assets/img/icons/VideoLine.svg'
import SocialMediaEmbed from '@/components/atoms/SocialMediaEmbed'

import { Colors } from '@/constants/styles/color'
import { StyledContainer, StyledListContents } from '../index.style'

interface Props {
  id: number
  link: string
}

const TikTok = ({ id, link }: Props) => {
  const { ref, inView } = useInView()

  return (
    <StyledListContents ref={ref} inView={inView}>
      <StyledContainer>
        {link ? (
          <SocialMediaEmbed id={id} type="tiktok" url={link} width={'100%'} height={'100%'} />
        ) : (
          <div
            style={{
              width: '100%',
              height: '176px',
              background: Colors.BK_GRAY,
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 8px',
            }}
          >
            <VideoLine />
            <VideoLine />
          </div>
        )}
      </StyledContainer>
    </StyledListContents>
  )
}

export default TikTok
