import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Button, Container, Image, Text } from '@nextui-org/react'

import { useThemeContext } from '@/contexts/ThemeContext'

import Official from '@/assets/img/icons/Official.svg'
import Login from '@/assets/img/icons/Login.svg'
import Share from '@/assets/img/icons/Share.svg'

import { Colors } from '@/constants/styles/color'

type Props = {
  contentsView: boolean
  creatorName: string
  officialFlag: boolean | undefined
  avatarImg?: string
  changeState: (event: React.SetStateAction<boolean>) => void
  onClickShare: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const CreatorStatus = ({
  contentsView,
  creatorName,
  officialFlag,
  avatarImg,
  changeState,
  onClickShare,
}: Props) => {
  const { isDark, themeColors } = useThemeContext()
  const themeCtx = useThemeContext()
  const [avatar, setAvatar] = useState<string | undefined>(avatarImg)

  // アバター画像・ユーザー名が画面外かどうかを検知
  const { ref, inView } = useInView({ rootMargin: '-20px 0px' })

  useEffect(() => {
    // クリエイターステータスボタンの表示・非表示を設定
    if (contentsView && !inView) {
      changeState(true)
    } else {
      changeState(false)
    }
  }, [inView, contentsView])

  useEffect(() => {
    setAvatar(avatarImg)
  }, [avatarImg])

  return (
    <div ref={ref}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          top: '-32px',
          textAlign: 'center',
        }}
      >
        {!avatar ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              background: Colors.LIGHT_GRAY,
              width: '64px',
              height: '64px',
              margin: '0 auto',
            }}
          >
            <Login fill={Colors.BLACK} style={{ width: '32px', height: '32px' }} />
          </div>
        ) : (
          <Image
            src={avatar}
            alt=""
            width={64}
            height={64}
            css={{ borderRadius: '50%' }}
            onError={() => setAvatar(undefined)}
          />
        )}
      </div>
      <Container
        css={{
          d: 'flex',
          jc: 'flex-end',
          ai: 'center',
          position: 'absolute',
          top: '12px',
          padding: '0 11px',
        }}
      >
        <Button
          onClick={onClickShare}
          icon={
            <Share
              style={{
                fill: `${isDark ? Colors.WHITE : Colors.BLACK}`,
                width: '24px',
                height: '24px',
              }}
            />
          }
          css={{
            br: '50%',
            h: '24px',
            w: '24px',
            minWidth: '24px',
            bg: themeCtx.color,
            zIndex: 0,
          }}
        />
      </Container>
      <Container
        css={{
          fontWeight: 'bold',
          color: Colors.WHITE,
          d: 'flex',
          jc: 'center',
          ai: 'center',
        }}
      >
        <Text
          size={13}
          css={{
            fontStyle: 'normal',
            lineHeight: '13px',
            textAlign: 'center',
            fontWeight: '700',
            color: themeCtx.themeColors.text,
            letterSpacing: '0em',
          }}
        >
          {creatorName}
          {officialFlag && <Official className={'ml-1.5 inline-block'} />}
        </Text>
      </Container>
    </div>
  )
}

export default CreatorStatus
