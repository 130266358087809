import React from 'react'
import { useInView } from 'react-intersection-observer'

import VideoLine from '@/assets/img/icons/VideoLine.svg'
import SocialMediaEmbed from '@/components/atoms/SocialMediaEmbed'

import { Colors } from '@/constants/styles/color'
import {
  StyledContainer,
  StyledDescriptionText,
  StyledListContents,
  StyledTitleText,
} from '../index.style'

interface Props {
  id: number
  link: string
  title: string
  description?: string
}

const Shorts = ({ id, link, title, description }: Props) => {
  const { ref, inView } = useInView()

  return (
    <StyledListContents ref={ref} inView={inView}>
      <StyledContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '8px',
            overflow: 'hidden',
            marginBottom: title || description ? '8px' : '0px',
            position: 'relative',
          }}
        >
          {link ? (
            <SocialMediaEmbed id={id} type="shorts" url={link} width={'100%'} />
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                background: Colors.BK_GRAY,
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 8px',
              }}
            >
              <VideoLine />
              <VideoLine />
            </div>
          )}
        </div>
        {(title || description) && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'grid',
                alignContent: 'space-around',
                gridTemplateRows: title && description ? '24px auto' : 'none',
              }}
            >
              {title && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <StyledTitleText className="jp-title middle">{title}</StyledTitleText>
                </div>
              )}
              {description && (
                <StyledDescriptionText className="jp-text small">
                  {description}
                </StyledDescriptionText>
              )}
            </div>
          </div>
        )}
      </StyledContainer>
    </StyledListContents>
  )
}

export default Shorts
