import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

export const StyledTextBox = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px 12px;
  background: ${Colors.ULTRA_LIGHT_GRAY};
  border-radius: 16px;
`

export const StyledTextBoxItem = styled.div<{ isExistItem: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => (props.isExistItem ? '16px' : '')};
`

export const StyledTextBoxTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
  text-align: left;
  color: ${Colors.BLACK};
  margin: 0;
`

export const StyledTextBoxDescription = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 160%;
  text-align: left;
  color: ${Colors.BLACK};
  margin: 0;
  white-space: pre-wrap;
  word-wrap: anywhere;
`
