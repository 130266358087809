import styled from '@emotion/styled'
import { Colors, contentShadow } from '@/constants/styles/color'
import { ThemeColors } from '@/contexts/ThemeContext'

const sizes = {
  lg: {
    width: '328px',
    height: '328px',
  },
  md: {
    width: '152px',
    height: '152px',
  },
  sm: {
    width: '96px',
    height: '96px',
  },
  xs: {
    width: '64px',
    height: '64px',
  },
}

const responsiveStyle = (size: keyof typeof sizes) => `
  width: min(${sizes[size].width}, calc(${sizes[size].width} - (365px - 100vw) * 0.3));
  height: min(${sizes[size].height}, calc(${sizes[size].height} - (365px - 100vw) * 0.3));
  min-width: 50px;
  min-height: 50px;
  border-radius: 16px;
  aspect-ratio: 1 / 1;
`

const StyledImageContainer = styled.div<{
  size: keyof typeof sizes
  boxShadow?: boolean
  isDark?: boolean
}>`
  ${({ size }) => responsiveStyle(size)}
  position: ${({ boxShadow }) => (boxShadow ? 'relative' : 'static')};
  box-shadow: ${({ boxShadow, isDark }) =>
    boxShadow ? `${isDark ? contentShadow.dark : contentShadow.light}` : ''};
  background: ${Colors.BK_GRAY};
`

const StyledImage = styled.img<{
  size: keyof typeof sizes
}>`
  object-fit: cover;
  ${({ size }) => responsiveStyle(size)}
`

const StyledText = styled.p<{ size: keyof typeof sizes; themeColors: ThemeColors }>`
  max-width: ${({ size }) => sizes[size].width};
  font-weight: 500;
  font-style: normal;
  margin: 8px 0 0;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: 11px;
  text-transform: none;
  color: ${({ themeColors }) => themeColors.text};
`

export { StyledImageContainer, StyledImage, StyledText }
