import React from 'react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'
import { TwitterShareButton } from 'react-share'
import { Colors } from '@/constants/styles/color'
import { CreatorData } from '@/types/Creator.type'

const StyledContainer = styled.div`
  position: absolute;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
  display: flex;
`

const StyledAlertBG = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledAlert = styled.div`
  display: flex;
  position: relative;
  background: ${Colors.WHITE};
  background-image: url('/ranking/gacha/background_miss.webp');
  background-size: 100% auto;
  background-position: center;
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  width: calc(100% - 80px);
  z-index: 10003;
  padding: 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

const StyledDialogIcon = styled.div`
  width: 64px;
  height: 64px;
  background: ${Colors.WHITE};
  border-radius: 50%;
  margin: -32px auto 0 auto;
`

const StyledDialogIconImage = styled.img`
  width: 64px;
  height: 64px;
`

const StyledMainTitle = styled.img`
  width: 90%;
  margin: 10px auto 20px auto;
`

const StyledActionButton = styled.button`
  height: 48px;
  width: 80%;
  background: ${Colors.BLACK};
  border: ${Colors.BLACK};
  color: ${Colors.WHITE};
  border-radius: 24px;
  margin: 0 24px 24px 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
`

interface Props {
  visible: boolean
  onClose: () => void
  creatorData: CreatorData
  hashtags: string[]
}

const RankingYellGachaMissDialog = ({ visible, onClose, creatorData, hashtags }: Props) => {
  const { publicRuntimeConfig } = getConfig()
  const router = useRouter()

  if (!visible) return null

  return (
    <>
      <StyledContainer>
        <StyledAlertBG />
        <StyledAlert>
          <StyledDialogIcon>
            <StyledDialogIconImage src="/ranking/gacha/gacha_dialog_icon.webp" />
          </StyledDialogIcon>

          <StyledMainTitle src="/ranking/gacha/text_miss.webp" />
          <TwitterShareButton
            url={`${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`}
            via={`FANME__official`}
            title={`🌟ランキングイベント開催中🌟\n#エールで応援\n「${creatorData.name}」さんを応援しにいこう！`}
            hashtags={hashtags}
          >
            <StyledActionButton>シェアする</StyledActionButton>
          </TwitterShareButton>

          <StyledCloseButtonContainer
            onClick={() => {
              onClose()
            }}
          >
            閉じる
          </StyledCloseButtonContainer>
        </StyledAlert>
      </StyledContainer>
    </>
  )
}

export default RankingYellGachaMissDialog
