import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { StyledListContents } from '../index.style'
import {
  StyledTextBox,
  StyledTextBoxDescription,
  StyledTextBoxItem,
  StyledTextBoxTitle,
} from './index.style'

interface Props {
  title: string
  description?: string
}

const TextBox = ({ title, description }: Props) => {
  const { ref, inView } = useInView()

  if (!title && !description) return null

  const textBoxContent = () => (
    <StyledTextBox>
      <StyledTextBoxItem isExistItem={title && description ? true : false}>
        <StyledTextBoxTitle>{title}</StyledTextBoxTitle>
        {description && <StyledTextBoxDescription>{description}</StyledTextBoxDescription>}
      </StyledTextBoxItem>
    </StyledTextBox>
  )

  return (
    <StyledListContents ref={ref} inView={inView}>
      {textBoxContent()}
    </StyledListContents>
  )
}

export default TextBox
